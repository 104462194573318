<template>
    <div class="container-with-fixed-bottom roadtrip-update with-botom-link">
        <LoadingIcon :isLoading="isLoading" />
        <ProductInformation :roadtrip="roadtrip" :isLoading="isLoading" @save="save" />
    </div>
</template>

<script>
import LoadingIcon from '@/components/common/LoadingIcon';
import ProductInformation from "@/components/admin/roadtrip/ProductInformation";
import { Photo } from '@/models/Roadtrip/Photo';
import { Roadtrip } from '@/models/Roadtrip/Roadtrip';
import RouteMixin from "@/mixins/route.mixin";
import FormatMixin from "@/mixins/format.mixin";
import RoadtripValidations from "@/mixins/validations/roadtrip-validations.mixin";
export default {
    name: 'RoadtripBestaandWijzigen',
    components: 
    {
        LoadingIcon,
        ProductInformation
    },
    mixins: [RouteMixin, FormatMixin, RoadtripValidations],
    data: () => ({
        deleteIcon: require('@/assets/icons/delete.svg'),
        roadtrip: new Roadtrip(),
        isLoading: false,
    }),
    mounted () 
    {
        this.roadtripId = this.$route.params.id;
        this.getProductInformation();
    },
    methods: 
    {
        getProductInformation()
        {
            let self = this;
            function setPlanTimes(plan) {
                plan.date = self.getDateOnly(plan.arrivalTime);
                plan.arrivalTimeOnly = self.getTimeOnly(plan.arrivalTime);
                plan.departureTimeOnly = self.getTimeOnly(plan.departureTime);
            }

            this.isLoading = true;
            this.$store.dispatch('productModule/getAdminProduct', this.roadtripId)
            .then(data => {
                this.roadtrip = data;
                this.roadtrip.isPublished = data.productInformation.productStatus == 2;

                this.roadtrip.participantsItinerary.forEach(plan => {
                    setPlanTimes(plan);
                });

                this.roadtrip.organizationItinerary.forEach(plan => {
                    setPlanTimes(plan);
                });

                this.setImages(data.productPhotos);
                this.isLoading = false;
            })
            .catch(() =>
            {
                this.isLoading = false;
            });
        },
        setImages(images)
        {
            this.roadtrip.images = images;
            // add default for upload image button in image carousel
            this.roadtrip.images.push(new Photo()); 
        },
        save()
        {
            this.isLoading = true;
            // set existing productphotos
            this.roadtrip.productPhotos = this.roadtrip.images.filter(x => x.productPhotoId != '' && x.productPhotoId != null);

            // set new photos
            this.roadtrip.newPhotos = this.roadtrip.images.filter(x => x.file != null).map(x => x.file);

            // check if there are images (existing + new).
            let errors = this.checkRoadtripPhotos(this.roadtrip.images.filter(x => x.photoURL != '' && x.photoURL != null));
            errors = errors + this.checkGPXFileChanges(this.roadtrip.gpx_file, this.roadtrip.gpx_file_changes)
            if(errors != "")
            {
                this.$store.commit('dialog/showDialog', errors);
                this.isLoading = false;
                return;
            }

            // TODO: Discuss how to send new photos (files)
            this.roadtrip.addProductPhotosCollection = {};
            this.$store.dispatch('roadtripModule/updateRoadtrip', this.roadtrip)
            .then(response => {
                // redirect to trip page
                var emailSentMessage = response ? "Er is een e-mail verzonden naar alle subscriebrs van de mailing." : "";
                this.$store.commit('dialog/showDialog', `Roadtrip succesvol geüpdatet. ${emailSentMessage}`);
                // This page is not fully implemented yet, redirect to dashboard for now.
                // TODO: #348 - To Roadtrip (Bestaand)
                //this.goToRoadtrip(this.roadtrip.roadtripId);
                this.goToMyDashboard();
            })
            .catch(() => {
                this.$store.commit('dialog/showDialog', 'Er ging iets mis, de roadtrip is niet correct opgeslagen.');
                this.isLoading = false;
            })
        }
    }
}
</script>

<style>


.editable-text .icon
{
    position: absolute;
    width: 15px;
    height: 15px;
    margin: 4px 7px;
}

.upload-image
{
    position: absolute;
    width: 130px;
    height: 130px;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.upload-container
{
    max-width: 100vw;
    position: relative;
    background-color: #0071E3;
}

.extra-options 
{
    position: relative;
    cursor: pointer;
}

.extra-options .v-icon
{
    position: absolute;
    right: -12px;
}

.roadtrip-update.with-botom-link
{
    padding-bottom: 60px;
    overflow-y: auto;
}

</style>

